// src/components/demo/DemoPage.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, increment, serverTimestamp } from 'firebase/firestore'; // Import increment and serverTimestamp
import { db } from '../firebase';
import { toast } from 'react-toastify';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { formatPhoneNumber } from '../utils/formatUtils';
import { updateAssistantDetails } from '../utils/assistantUtils';
import { CheckIcon } from '@heroicons/react/24/solid';

const DemoPage = () => {
  const { uniqueKey } = useParams();
  const navigate = useNavigate(); // Get navigate function
  const [isLoading, setIsLoading] = useState(true);
  const [prospectData, setProspectData] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    assistantDetails: { knowledge: '', name: '', answersFor: '' }
  });
  const [demoText, setDemoText] = useState('Preview your assistant');
  const [isDemoButtonDisabled, setIsDemoButtonDisabled] = useState(false);
  const [isDemoLoading, setIsDemoLoading] = useState(false);
  const [demoTimer, setDemoTimer] = useState(null);

  const functions = getFunctions();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, 'Prospects', uniqueKey);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setProspectData(docSnap.data());

          // **Update the 'visited' status and 'lastVisited' timestamp**
          await updateDoc(docRef, {
            lastVisited: serverTimestamp(),
            visitCount: increment(1), // Increment the visit count
          });
        } else {
          toast.error('No such prospect found.');
        }
      } catch (error) {
        console.error('Error fetching prospect data:', error);
        toast.error('Failed to load prospect data.');
      }
      setIsLoading(false);
    };

    fetchData();
  }, [uniqueKey]);

  const handleInputChange = (field, value) => {
    setProspectData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleAssistantChange = (field, value) => {
    setProspectData(prevState => ({
      ...prevState,
      assistantDetails: {
        ...prevState.assistantDetails,
        [field]: value
      }
    }));
  };

  // Keep the saveChanges function
  const saveChanges = async () => {
    try {
      const docRef = doc(db, 'Prospects', uniqueKey);
      await updateDoc(docRef, { ...prospectData });
      toast.success('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving changes:', error);
      toast.error('Failed to save changes.');
    }
  };

  const handleDemoClick = async () => {
    // Call saveChanges after clicking the demo button
    await saveChanges();

    const { companyName, firstName, lastName, email, assistantDetails } = prospectData;

    if (
      !companyName ||
      !firstName ||
      !lastName ||
      !email ||
      !assistantDetails.name ||
      !assistantDetails.answersFor ||
      !assistantDetails.knowledge
    ) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsDemoLoading(true);
    const checkoutDemoPhoneNumber = httpsCallable(functions, 'checkoutDemoPhoneNumber');
    try {
      const requestData = { uniqueKey: uniqueKey };
      const result = await checkoutDemoPhoneNumber(requestData);
      const phoneNumber = result.data.phoneNumber;
      if (!phoneNumber) {
        throw new Error('Invalid response from server');
      }
      const phoneNumberClean = formatPhoneNumber(phoneNumber);
      const docRef = doc(db, 'Prospects', uniqueKey);

      // Call the updated utility function
      await updateAssistantDetails(
        uniqueKey, // Using uniqueKey as documentId for Prospects
        {
          assistantName: assistantDetails.name,
          answersFor: assistantDetails.answersFor,
          knowledge: assistantDetails.knowledge,
          companyName: companyName,
          firstName: firstName,
          lastName: lastName,
          recordedLineNotify: 0,
          calendarExists: false,
        },
        'Prospects'
      );

      await updateDoc(docRef, {
        assistantPhoneNumber: phoneNumber,
      });

      setDemoText(`Call to test your assistant: ${phoneNumberClean}`);
      setIsDemoButtonDisabled(true);
      setIsDemoLoading(false);

      const timer = setTimeout(async () => {
        setDemoText('Preview your assistant');
        setIsDemoButtonDisabled(false);

        // Log the uniqueKey and the intended operation
        console.log(`Clearing assistantPhoneNumber for uniqueKey: ${uniqueKey}`);

        try {
          const docRef = doc(db, 'Prospects', uniqueKey);
          await updateDoc(docRef, { assistantPhoneNumber: '' });
          console.log(`assistantPhoneNumber cleared for uniqueKey: ${uniqueKey}`);
        } catch (error) {
          console.error(`Error clearing assistantPhoneNumber for uniqueKey: ${uniqueKey}`, error);
          // Optionally, display a toast message if needed
          toast.error(`Failed to clear assistant phone number: ${error.message}`);
        }
      }, 600000); // 10 minutes

      setDemoTimer(timer);
    } catch (error) {
      console.error('Error fetching demo phone number:', error);
      toast.error(`Failed to fetch demo phone number: ${error.message}`);
      setIsDemoLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      if (demoTimer) {
        clearTimeout(demoTimer);
      }
    };
  }, [demoTimer]);

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden md:max-w-lg">
        <div className="w-full p-5">

          {/* New box starts here */}
          <div className="mb-12">
            <h1 className="text-3xl font-bold text-center">Voicemail sucks.</h1>
            <p className="text-lg text-center mt-2">
              Use <span className="font-semibold">Ring-a-Ling</span> instead: a personalized AI phone answering assistant.
            </p>
            <ul className="mt-6 space-y-3">
              <li className="flex items-center">
                <CheckIcon className="w-6 h-6 text-green-500 mr-3" />
                <span className="text-gray-700">Answers your phone after hours / when you're busy</span>
              </li>
              <li className="flex items-center">
                <CheckIcon className="w-6 h-6 text-green-500 mr-3" />
                <span className="text-gray-700">Sounds exactly like a person</span>
              </li>
              <li className="flex items-center">
                <CheckIcon className="w-6 h-6 text-green-500 mr-3" />
                <span className="text-gray-700">Knows you and your company</span>
              </li>
              <li className="flex items-center">
                <CheckIcon className="w-6 h-6 text-green-500 mr-3" />
                <span className="text-gray-700">Can book meetings &amp; take messages</span>
              </li>
            </ul>
          </div>
          {/* New box ends here */}

          <h2 className="text-2xl font-semibold text-gray-700 text-center">Personalized Demo</h2>
          <div className="text-sm font-bold text-gray-700 mt-4">Company Name:</div>
          <input
            type="text"
            value={prospectData.companyName}
            onChange={(e) => handleInputChange('companyName', e.target.value)}
            placeholder="Company Name"
            className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
          />
          <div className="text-sm font-bold text-gray-700 mt-4">First Name:</div>
          <input
            type="text"
            value={prospectData.firstName}
            onChange={(e) => handleInputChange('firstName', e.target.value)}
            placeholder="First Name"
            className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
          />
          <div className="text-sm font-bold text-gray-700 mt-4">Last Name:</div>
          <input
            type="text"
            value={prospectData.lastName}
            onChange={(e) => handleInputChange('lastName', e.target.value)}
            placeholder="Last Name"
            className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
          />
          <div className="text-sm font-bold text-gray-700 mt-4">Email:</div>
          <input
            type="email"
            value={prospectData.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            placeholder="Email"
            className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
          />
          <div className="text-sm font-bold text-gray-700 mt-4">Assistant Knowledge:</div>
          <textarea
            value={prospectData.assistantDetails.knowledge}
            onChange={(e) => handleAssistantChange('knowledge', e.target.value)}
            placeholder="Assistant Knowledge"
            className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500 h-40"
          />
          <div className="text-sm font-bold text-gray-700 mt-4">Assistant Name:</div>
          <input
            type="text"
            value={prospectData.assistantDetails.name}
            onChange={(e) => handleAssistantChange('name', e.target.value)}
            placeholder="Assistant Name"
            className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
          />
          <div className="text-sm font-bold text-gray-700 mt-4">Answers For:</div>
          <select
            value={prospectData.assistantDetails.answersFor}
            onChange={(e) => handleAssistantChange('answersFor', e.target.value)}
            className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
          >
            <option value="">Select...</option>
            <option value="You">You - "Hello this is *assistant-name*, *your-name*'s assistant."</option>
            <option value="Company">Your Company - "Hello this is *assistant-name* from *your-company*."</option>
          </select>
          {/* Switch the positions of the two buttons */}
          <button
            onClick={handleDemoClick}
            disabled={isDemoButtonDisabled || isDemoLoading}
            className={`w-full mt-6 ${
              isDemoButtonDisabled || isDemoLoading
                ? 'bg-gray-400'
                : 'bg-green-800 hover:bg-green-900'
            } text-white font-semibold py-3 px-4 rounded-md`}
          >
            {isDemoLoading ? (
              <div className="flex justify-center items-center">
                <div
                  className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full"
                  role="status"
                >
                  <span className="visually-hidden">.</span>
                </div>
              </div>
            ) : (
              demoText
            )}
          </button>

          {/* Change the "Save Changes" button to redirect to the sign-up page */}
          <button
            onClick={() => navigate(`/signup?prospectDocID=${uniqueKey}`)}
            className="w-full mt-6 bg-green-800 hover:bg-green-900 text-white font-semibold py-3 px-4 rounded-md"
          >
            Get your assistant now!
          </button>
        </div>
      </div>
    </div>
  );
};

export default DemoPage;