// src/pages/CallsPage.js
import React, { useEffect, useState, useRef } from 'react';
import { collection, doc, getDocs, query, orderBy, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import Navbar from '../components/Navbar';
import { useGlobalContext } from '../contexts/GlobalContext';
import { formatPhoneNumber } from '../utils/formatUtils';

const DeleteModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg">
        <p className="mb-4">This will completely & permanently delete this call transcript. This cannot be reversed. Are you sure you want to delete it?</p>
        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={onConfirm}>
          Yes, Delete!
        </button>
        <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={onClose}>
          No, Keep it.
        </button>
      </div>
    </div>
  );
};

const CallLogEntry = ({ callId, callStart, callDuration, callerName, callerNumber, messages, onDelete }) => {
  const callEnd = new Date(callStart.toDate().getTime() + callDuration * 1000);
  const formattedNumber = formatPhoneNumber(callerNumber);

  return (
    <div className="bg-gray-100 p-4 rounded-lg mb-4">
      <div className="flex justify-between items-center mb-2">
        <div>
          <div className="font-bold">
            {callStart.toDate().toLocaleString()} - {callEnd.toLocaleTimeString()}
          </div>
          <div className="text-gray-600">Caller: {formattedNumber}</div>
        </div>
        <div>
          <div className="font-bold text-gray-600">{callerName}</div>
          <button
            className="text-red-500 hover:text-red-700 font-bold p-1"
            onClick={() => onDelete(callId)}
          >
            &times;
          </button>
        </div>
      </div>
      <div className="space-y-2">
        {messages.map((msg, index) => {
          const isAssistant = msg.messageSpeaker === 'Assistant';
          return (
            <div
              key={index}
              className={`flex ${isAssistant ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`inline-block px-4 py-2 rounded-lg ${
                  isAssistant ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'
                } ${isAssistant ? 'ml-12' : 'mr-12'} max-w-lg`}
              >
                {msg.messageText}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CallsPage = () => {
  const { user } = useGlobalContext();
  const [callLogs, setCallLogs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCallId, setSelectedCallId] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    if (!user) return;

    isMounted.current = true;

    const fetchCalls = async () => {
      try {
        const callsRef = collection(db, "Users", user.uid, "Calls");
        const callsQuery = query(callsRef, orderBy("callStart", "desc"));
        const callsSnap = await getDocs(callsQuery);

        const callsData = await Promise.all(callsSnap.docs.map(async (docSnap) => {
          const callData = docSnap.data();
          const messagesRef = collection(db, "Users", user.uid, "Calls", docSnap.id, "Messages");
          const messagesQuery = query(messagesRef, orderBy("messageNum", "asc"));
          const messagesSnap = await getDocs(messagesQuery);

          const messagesData = messagesSnap.docs.map(msgDocSnap => msgDocSnap.data());
          return {
            callId: docSnap.id,
            ...callData,
            messages: messagesData,
          };
        }));

        if (isMounted.current) setCallLogs(callsData);
      } catch (error) {
        console.error("Failed to fetch calls:", error);
      }
    };

    fetchCalls();

    return () => {
      isMounted.current = false;
    };
  }, [user]);

  const handleDelete = async (callId) => {
    try {
      // Reference to the Messages subcollection
      const messagesRef = collection(db, "Users", user.uid, "Calls", callId, "Messages");
      const messagesSnap = await getDocs(messagesRef);

      // Delete each message document individually
      const deleteMessagesPromises = messagesSnap.docs.map((msgDocSnap) => {
        return deleteDoc(msgDocSnap.ref);
      });
      await Promise.all(deleteMessagesPromises);

      // Now delete the parent call document
      await deleteDoc(doc(db, "Users", user.uid, "Calls", callId));

      // Update local state
      setCallLogs(callLogs.filter(log => log.callId !== callId));
      closeModal();
    } catch (error) {
      console.error("Failed to delete call:", error);
    }
  };

  const openModal = (callId) => {
    setSelectedCallId(callId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedCallId(null);
  };

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <div className="max-w-2xl mx-auto py-10 px-4">
        <div className="flex items-center mb-10">
          <span className="text-2xl font-bold">Calls</span>
        </div>

        {callLogs.length > 0 ? (
          callLogs.map((log, index) => (
            <CallLogEntry key={index} {...log} onDelete={openModal} />
          ))
        ) : (
          <div>No call logs available.</div>
        )}

        <DeleteModal isOpen={modalOpen} onClose={closeModal} onConfirm={() => handleDelete(selectedCallId)} />
      </div>
    </div>
  );
};

export default CallsPage;