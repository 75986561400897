// src/components/onboarding/CallForwarding.js
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import getInstructions from '../../utils/instructionsMapping';


const CallForwarding = ({ onFinish }) => {
  const { user, updateOnboardingStatus } = useGlobalContext();
  const [assistantPhoneNumber, setAssistantPhoneNumber] = useState('+15556667777'); // Default placeholder
  const [phoneType, setPhoneType] = useState('');
  const [phoneCarrier, setPhoneCarrier] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userRef = doc(db, 'Users', user.uid);
      try {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setAssistantPhoneNumber(docSnap.data().assistantPhoneNumber);
          setPhoneType(docSnap.data().phoneType);
          setPhoneCarrier(docSnap.data().phoneCarrier);
          
        } else {
          console.error('No such user!');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        toast.error('Failed to fetch user details. Please reload the page.');
      }
    };

    fetchUserDetails();
  }, [user.uid]);

  const completeOnboarding = async () => {
    const userRef = doc(db, 'Users', user.uid);
    try {
      await updateDoc(userRef, {
        onboardingStatus: 5, // Assuming 5 represents the completion of onboarding
      });
      // onFinish(); // Assuming onFinish will navigate the user to a new page or refresh the current page
    } catch (error) {
      console.error('Error completing onboarding:', error);
      toast.error('Failed to complete onboarding. Please try again.');
    }
  };

  let instructionType = `${phoneType}-${phoneCarrier}`;
  if (assistantPhoneNumber.substring(0, 3) === '+44') {
    instructionType = 'All-UK';
  }
  
  const instructions = getInstructions(assistantPhoneNumber)[instructionType] || <p>Instructions not available: trying googling "busy / no answer call forwarding" + your phone type + carrier.</p>;


  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden md:max-w-lg">
        <div className="md:flex">
          <div className="w-full p-5">
          <h2 className="text-2xl font-semibold text-gray-700 text-center mb-4">Call Forwarding Setup</h2>
            {instructions}
            <button
              onClick={completeOnboarding}
              className="w-full mt-6 bg-green-800 hover:bg-green-900 text-white font-semibold py-3 px-4 rounded-md"
            >
              Finish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallForwarding;
