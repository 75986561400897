// src/pages/SettingsPage.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../firebase';
import Navbar from '../components/Navbar';
import { useGlobalContext } from '../contexts/GlobalContext';
import { toast } from 'react-toastify';

import { getAuth, linkWithPopup, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { updateAssistantDetails } from '../utils/assistantUtils';
import { formatPhoneNumber } from '../utils/formatUtils';

// Timezone options definition
const timezoneOptions = [
  { label: "Eastern Time (ET)", value: "America/New_York" },
  { label: "Central Time (CT)", value: "America/Chicago" },
  { label: "Mountain Time (MT)", value: "America/Denver" },
  { label: "Pacific Time (PT)", value: "America/Los_Angeles" }
];

const SettingsPage = () => {
  const { user, checkProfileComplete } = useGlobalContext(); // Use user from global context
  console.log("Current user:", user); // Diagnostic log for user

  const [companyName, setCompanyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [assistantName, setAssistantName] = useState('');
  const [answersFor, setAnswersFor] = useState('');
  const [knowledge, setKnowledge] = useState('');
  const [recordedLineNotify, setRecordedLineNotify] = useState(0);
  const [assistantPhoneNumber, setAssistantPhoneNumber] = useState('');
  const [calendarExists, setCalendarExists] = useState(false);
  const [timezone, setTimezone] = useState('');

  const functions = getFunctions();

  // Add useState for portalUrl
  const [portalUrl, setPortalUrl] = useState(''); // DDAY

  useEffect(() => {
    if (user && user.uid) { // Make sure user object has uid
      const docRef = doc(db, 'Users', user.uid);
      getDoc(docRef).then(docSnap => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log("Document data:", data); // Diagnostic log for fetched data
          setCompanyName(data.companyName || '');
          setFirstName(data.firstName || '');
          setLastName(data.lastName || '');
          setPhoneNumber(data.phoneNumber || '');
          setAssistantPhoneNumber(data.assistantPhoneNumber || '');
          setTimezone(data.timezone || '');
          if (data.assistantDetails) {
            setAssistantName(data.assistantDetails.name || '');
            setAnswersFor(data.assistantDetails.answersFor || '');
            setKnowledge(data.assistantDetails.knowledge || '');
            setRecordedLineNotify(data.assistantDetails.recordedLineNotify || 0);
          }
          setCalendarExists(!!data.calendarTokens); // Set calendarExists based on calendarTokens presence

          // Fetch Stripe Customer Portal URL if stripeCustomerID exists
          if (data.stripeCustomerID) {
            const getPortalUrl = httpsCallable(functions, 'getStripeCustomerPortalUrl');
            getPortalUrl({ customerId: data.stripeCustomerID })
              .then((result) => {
                setPortalUrl(result.data.url);
              })
              .catch((error) => {
                console.error('Error getting Stripe Customer Portal URL:', error);
                toast.error('Failed to load billing management link.', error);
              });
          }

        } else {
          console.log("No user document found!");
        }
      });
    }
  }, [user]);

  // Save changes to settings
  const handleSave = async () => {
    if (user && user.uid) {
      // Data not handled by updateAssistantDetails (general user information)
      const generalUserData = {
        companyName, 
        firstName, 
        lastName, 
        phoneNumber,
        timezone
      };
  
      // Prepare assistantData for the updateAssistantDetails utility function
      const assistantData = {
        assistantName, 
        answersFor, 
        knowledge, 
        companyName, // companyName might still be needed within the assistantDetails context
        firstName, 
        lastName,
        recordedLineNotify,
        calendarExists
      };
  
      try {
        // First, update general user data with setDoc for an overwrite merge behavior
        await setDoc(doc(db, 'Users', user.uid), generalUserData, { merge: true });
        
        // Use the utility function to update assistantDetails
        // i.e. also updates the assistantPrompt & beginSentence (lots of text)
        await updateAssistantDetails(user.uid, assistantData);
  
        toast.success("Settings saved successfully.");
      } catch (error) {
        console.error("Error saving settings:", error);
        toast.error("Failed to save settings. Please try again.");
      }
    } else {
      toast.error("User information is missing.");
    }
  };

  // Utility function to format phone number
  const formatInputPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


  const handleLinkGoogleAccount = async () => {

    const auth = getAuth();

    // Check if the Google account is already linked
    const isGoogleLinked = auth.currentUser.providerData.some(
        (data) => data.providerId === 'google.com'
    );

    // Fetch scopes from Firestore
    const docRef = doc(db, 'Users', user.uid);
    const docSnapshot = await getDoc(docRef);
    let scopes = [];
    if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const calendarTokens = userData.calendarTokens || {};  // Safely access the calendarTokens map
        scopes = calendarTokens.scopes || [];
        // Now you can use `scopes` to check the permissions
    } else {
        console.log("Document not found");
        toast.error("User document not found.");
    }

    const hasCalendarScope = scopes.includes('https://www.googleapis.com/auth/calendar');

    if (isGoogleLinked && hasCalendarScope) {
        // Inform the user that the Google Calendar is already linked
        console.log("Google Calendar is already linked.");
        toast.info("Google Calendar already linked.");
    } else {
        // Either not linked or does not have the calendar scope
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/calendar');
        provider.setCustomParameters({
            access_type: 'offline',  // Important for refresh tokens
            prompt: 'consent'        // Force the consent screen to ensure access token validity
        });

        try {
            // Link the account or re-authenticate to get the missing scope
            if (isGoogleLinked){
              await signInWithPopup(auth, provider);
              console.log("Re-authenticated with additional scope.");
            } else {
              await linkWithPopup(auth.currentUser, provider);
              console.log("Account linked with new provider.");
            }
            // Check if the user granted the calendar scope after popup
            const docRef = doc(db, 'Users', user.uid);
            const updatedDocSnapshot = await getDoc(docRef);
            let updatedScopes = [];
            if (updatedDocSnapshot.exists()) {
              const userData = updatedDocSnapshot.data();
              const calendarTokens = userData.calendarTokens || {};  // Safely access the calendarTokens map
              updatedScopes = calendarTokens.scopes || [];
              // Now you can use `updatedScopes` to check the permissions
            } else {
                console.log("Document not found");
                toast.error("User document not found.");
            }

            if (updatedScopes.includes('https://www.googleapis.com/auth/calendar')) {
                // If the calendar scope is now present, update the assistant details
                await delay(2000); // Delay to ensure the token is updated in Firestore
                const assistantData = {
                    assistantName,
                    answersFor,
                    knowledge,
                    companyName,
                    firstName,
                    lastName,
                    recordedLineNotify,
                    calendarExists: true
                };
                await updateAssistantDetails(auth.currentUser.uid, assistantData);
                toast.success("Google Calendar linked successfully");
            } else {
                // If the user still did not grant the calendar scope
                console.log("User did not check the share calendar box.");
                toast.error("Error connecting Google calendar: please check the 'Share Calendar' during sign in process.");
            }
        } catch (error) {
            console.error("Error during account linking or settings update:", error);
            toast.error("Failed to link Google account and update settings.");
        }
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <div className="max-w-2xl mx-auto py-10 px-4">
        {/* USER SETTING HEADER */}
        <h1 className="text-2xl font-bold mb-6">User Settings</h1>
        {/* USER SETTINGS */}
        <div className="bg-gray-100 p-4 rounded-lg mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Company Name</label>
            <input
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">First Name</label>
            <input
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Last Name</label>
            <input
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
            <input
              type="tel"
              pattern="(\([0-9]{3}\) [0-9]{3}-[0-9]{4})"
              placeholder="(555) 555-5555"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(formatInputPhoneNumber(e.target.value))}
            />
          </div>

          {/* Timezone dropdown */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Timezone</label>
            <select
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
            >
              <option value="">Select Timezone</option>
              {timezoneOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* Billing Link */}
          <div className="mb-4">
            <a href={portalUrl || '#'}
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-800">Manage Billing</a>
          </div>
        </div>
        
        {/* ASSISTANT SETTINGS HEADER */}
        <h1 className="text-2xl font-bold mb-6">Assistant Settings</h1>
        {/* ASSISTANT SETTINGS */}
        <div className="bg-gray-100 p-4 rounded-lg mb-4">
          {/* Assistant's Name */}
          <div className="mb-4">
            <label htmlFor="assistant-name" className="block text-gray-700 text-sm font-bold mb-2">Assistant's Name</label>
            <input
              id="assistant-name"
              type="text"
              value={assistantName}
              onChange={(e) => setAssistantName(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
            />
          </div>

          {/* Answers For */}
          <div className="mb-4">
            <label htmlFor="answers-for" className="block text-gray-700 text-sm font-bold mb-2">
              Answers For
            </label>
            <select
              id="answers-for"
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight"
              value={answersFor}
              onChange={(e) => setAnswersFor(e.target.value)}
            >
              <option value="" disabled>Select</option> {/* Placeholder option */}
              <option value="You">You</option>
              <option value="Company">Company</option>
            </select>
          </div>

          {/* Knowledge */}
          <div className="mb-4">
            <label htmlFor="knowledge" className="block text-gray-700 text-sm font-bold mb-2">Knowledge</label>
            <textarea
              id="knowledge"
              value={knowledge}
              onChange={(e) => setKnowledge(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight h-48"
            />
          </div>

          {/* Assistant Phone Number */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Assistant's Phone Number
            </label>
            <p className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700">
              {formatPhoneNumber(assistantPhoneNumber) || 'Not yet assigned'} 
            </p>
          </div>

          {/* Line Notify Toggle */}
          <div className="mb-4">
            <label htmlFor="line-notify-toggle" className="block text-gray-700 text-sm font-bold mb-2">
              Recorded Line Notification:
            </label>
            <label className="switch">
              <input
                id="line-notify-toggle"
                type="checkbox"
                checked={recordedLineNotify === 1} // Set initial state based on existing value
                onChange={(e) => setRecordedLineNotify(e.target.checked ? 1 : 0)}
              />
              <span className="slider round"></span>
            </label>
          </div>
          {/* Link Google Account button*/}
          <button
            onClick={handleLinkGoogleAccount}
            className="bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Link Google Calendar
          </button>

        </div>

        {/* Save Button */}
        <button
          onClick={handleSave}
          className="bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default SettingsPage;