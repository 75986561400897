// src/pages/SignUpPage.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { toast } from 'react-toastify';
import '../assets/styles/GoogleButton.css';

const SignUpPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [prospectDocID, setProspectDocID] = useState(null);
    const [prospectData, setProspectData] = useState(null);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Extract prospectDocID from URL query parameters
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const docID = queryParams.get('prospectDocID');
        if (docID) {
            setProspectDocID(docID);
        }
    }, [location.search]);

    // Fetch prospect data if prospectDocID is available
    useEffect(() => {
        const fetchProspectData = async () => {
            if (prospectDocID) {
                try {
                    const prospectRef = doc(db, 'Prospects', prospectDocID);
                    const prospectSnap = await getDoc(prospectRef);

                    if (prospectSnap.exists()) {
                        setProspectData(prospectSnap.data());
                    } else {
                        console.error('Prospect document does not exist');
                    }
                } catch (error) {
                    console.error('Error fetching prospect data:', error);
                }
            }
        };
        fetchProspectData();
    }, [prospectDocID]);

    const handleSignUpWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/calendar');
        provider.setCustomParameters({
            access_type: 'offline',  // Important for refresh tokens
            prompt: 'consent'        // Force the consent screen to ensure access token validity
        });
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            // const userRef = doc(db, 'Users', user.uid);
            // const docSnap = await getDoc(userRef);
            
            console.log("User document ID: ",user.uid )
            navigate('/onboarding');
            /*
            if (!docSnap.exists()) {
                // New user
                await setDoc(userRef, {
                    email: user.email,
                    displayName: user.displayName,
                }, { merge: true });
                navigate('/onboarding');
            } else {
                // Existing user
                toast.info("You already have an account! Logging you in!");
                navigate('/settings');
            }
            */
        } catch (error) {
            setError(error.message);
            // Handle other potential errors.
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            const result = await createUserWithEmailAndPassword(auth, email, password);

            const userDoc = {
                email: result.user.email,
                // Add any default fields you wish to store
            };

            // If prospect data is available, copy over specific fields
            if (prospectData) {
                userDoc.timezone = prospectData.timezone || '';
                userDoc.firstName = prospectData.firstName || '';
                userDoc.lastName = prospectData.lastName || '';
                userDoc.companyName = prospectData.companyName || '';
                userDoc.assistantDetails = {
                    answersFor: prospectData.assistantDetails?.answersFor || '',
                    knowledge: prospectData.assistantDetails?.knowledge || '',
                    name: prospectData.assistantDetails?.name || ''
                };

                // Optionally delete the prospect document
                // const prospectRef = doc(db, 'Prospects', prospectDocID);
                // await deleteDoc(prospectRef);
            }

            // Save userDoc to Users collection
            await setDoc(doc(db, 'Users', result.user.uid), userDoc, { merge: true });

            navigate('/onboarding');
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                // Specific error handling for existing email
                toast.error("An account with this email already exists. Please log in.");
                // Optionally, redirect to the login page or provide a login form/modal here
                // navigate('/login', { state: { email } }); // Example redirect
            } else {
                // Handle other errors
                setError(error.message);
            }
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md text-center">
                <div className="mb-4">
                    <div className="text-4xl text-gray-800 mb-8">Ring-a-Ling</div>
                    
                    <button className="gsi-material-button">
                        <div className="gsi-material-button-state"></div>
                        <div className="gsi-material-button-content-wrapper" onClick={handleSignUpWithGoogle}>
                            <div className="gsi-material-button-icon">
                                <svg version="1.1" viewBox="0 0 48 48" style={{ display: 'block' }}>
                                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                </svg>
                            </div>
                            <span className="gsi-material-button-contents">Sign up with Google</span>
                        </div>
                    </button>

                    <div className="my-4">OR</div>

                    <form onSubmit={handleSignUp}>
                        <input
                            type="email"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-4"
                            id="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-4"
                            id="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            type="submit"
                            className="bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 rounded mx-2"
                        >
                            Sign Up
                        </button>
                    </form>

                    {/* Error Message Display */}
                    {error && <p className="text-red-500 text-xs mt-2">{error}</p>}

                    {/* Link to Log in */}
                    <div className="mt-4">
                        Have an account already?
                        <Link to="/login" className="text-blue-500 hover:text-blue-800 ml-1">
                            Log in here
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SignUpPage;